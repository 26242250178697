<template>
  <v-row>
    <v-col cols="12" xs="12" md="12" lg="12" xl="12" class="pb-0">
      <v-textarea
        ref="contractDescription"
        v-model="computedDescription"
        :label="$t('description')"
        rows="2"
        outlined
        @keyup="onDescriptionChange"
        required
      />
    </v-col>
    <v-col cols="12" xs="12" md="12" lg="12" xl="12" class="py-0">
      <v-btn
        color="primary"
        x-small
        class="mr-2"
        elevation="2"
        style="text-transform: unset !important"
        @click="insertVariable('mesAnterior')"
      >
        mesAnterior
      </v-btn>
      <v-btn
        color="primary"
        x-small
        class="mr-2"
        elevation="2"
        style="text-transform: unset !important"
        @click="insertVariable('mesActual')"
      >
        mesActual
      </v-btn>
      <v-btn
        color="primary"
        x-small
        class="mr-2"
        elevation="2"
        style="text-transform: unset !important"
        @click="insertVariable('mesSiguiente')"
      >
        mesSiguiente
      </v-btn>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class ContractDescription extends Vue {
  @Prop(String) description!: string;

  computedDescription = "";

  mounted() {
    this.computedDescription = this.description;
  }

  @Watch("description")
  updateDescription() {
    this.computedDescription = this.description;
  }

  insertVariable(varName: string) {
    const descriptionRef = this.$refs.contractDescription as any;
    const curpos =
      descriptionRef.$refs?.input.selectionStart ||
      this.computedDescription.length;
    this.computedDescription = `${this.computedDescription.slice(
      0,
      curpos
    )} {${varName}} ${this.computedDescription.slice(curpos)}`;
    this.$emit("change", this.computedDescription);
  }

  onDescriptionChange() {
    this.$emit("change", this.computedDescription);
  }
}
</script>
